<template>
    <div>
        <router-view />
    </div>
</template>

<script>
import { AUTH_LAYOUT, HOME_VIEW } from "@/router/constants";
import { mapActions } from "vuex";
import { CHECK_TOKEN, LOGIN_MODULE } from "@/store/constants/login";

export default {
    name: AUTH_LAYOUT,
    methods: {
        ...mapActions({
            checkToken: `${LOGIN_MODULE}/${CHECK_TOKEN}`,
        }),
    },

    async created() {
        const hasToken = await this.checkToken();

        if (hasToken) {
            this.$router.push({ name: HOME_VIEW });
        }
    },
};
</script>

<style></style>
